var Emico = Emico || {};

Emico.StickyHeader = class {
    constructor(options) {
        this.toggleSticky = this.toggleSticky.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        this.options = jQuery.extend({}, {
            headerSelector: '.sticky-header-wrapper'
        }, options);

        this.stickyHeader();
    }

    toggleSticky() {
        const headerSelector = jQuery(this.options.headerSelector);

        if(jQuery(window).scrollTop() > 55 ) {
            headerSelector.addClass('sticky');
        } else {
            headerSelector.removeClass('sticky');
        }
    }

    stickyHeader() {
        let scrollTimer;

        this.toggleSticky();

        jQuery(window).on('scroll', () => {
            cancelAnimationFrame(scrollTimer);
            scrollTimer = requestAnimationFrame(this.toggleSticky);
        });
    }
};

jQuery(function () {
    new Emico.StickyHeader();
});